// External Dependencies
import React from 'react';
import { Link } from 'gatsby';

// Internal Dependencies
import HeaderGeneric from '../components/HeaderGeneric';
import Layout from '../components/layout';
import Meta from '../components/Meta';

// Component Definition
const SwMusic2021RaffleEntry = () => (
  <Layout>
    <Meta title="Raffle Entry" />
    <HeaderGeneric
      hideLogo
      subtitle="Today's winner will be announced via email and social media tonight."
      title="Thank you for entering the 2021 Southwest Music Summer Exhibition raffle!"
    />
    <div className="center">
      <Link
        className="button special-2"
        to="/"
      >
        Home
      </Link>
    </div>
  </Layout>
);

export default SwMusic2021RaffleEntry;
